var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('div',{},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h4',{staticClass:"my-0"},[_vm._v("Files We've Uploaded")]),_c('p',[_vm._v(" Note: These are files uploaded by our team. I.e. Writer, editor or support. ")]),(
              _vm.order.files.our_uploads && _vm.order.files.our_uploads.length > 0
            )?_c('div',{staticClass:"bg-light border-0 rounded p-0 mb-2"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.order.files.our_uploads),function(file,index){return _c('tr',{key:index + 'ofiles'},[_c('td',[_c('a',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","word-break":"break-all"},on:{"click":function($event){return _vm.downloadFile(file)}}},[_vm._v(_vm._s(file.file_name || ""))])]),_c('td',[_vm._v(_vm._s(file.date || ""))]),_c('td',[_vm._v(_vm._s(file.type || ""))]),_c('td',[(_vm.is_downloading && _vm.activeFile.id == file.id)?_c('span',[_c('img',{attrs:{"width":"30px","src":_vm.loading_gif,"alt":"Loading..."}})]):_c('button',{staticClass:"btn btn-outline-success btn-sm",on:{"click":function($event){return _vm.downloadFile(file)}}},[_c('span',{staticClass:"icon-download mr-0 mr-sm-1"}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Download")])])])])})],2)])]):_c('p',{staticClass:"p-2 bg-light border-0 rounded"},[_vm._v(" No order files. "),_c('br'),_vm._v(" Files that we have uploaded will appear here. ")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('h4',{staticClass:"my-0"},[_vm._v("Upload Files")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-7 col-md-9"},[_c('FileUploader',{model:{value:(_vm.orderForm.files),callback:function ($$v) {_vm.$set(_vm.orderForm, "files", $$v)},expression:"orderForm.files"}})],1),_c('div',{staticClass:"col-12 col-sm-4 col-md-3 pl-2 pl-sm-0"},[_c('button',{staticClass:"btn btn-primary btn-block",staticStyle:{"padding-top":"8px","padding-bottom":"8px","margin-top":"2px"},attrs:{"disabled":_vm.is_uploading},on:{"click":_vm.uploadFiles}},[_vm._v(" "+_vm._s(_vm.is_uploading ? "Uploading" : "Upload")+" ")])])]),_c('h4',{staticClass:"my-0"},[_vm._v(" Your Uploads ")]),(
              _vm.order.files.client_uploads &&
              _vm.order.files.client_uploads.length > 0
            )?_c('div',{staticClass:"bg-light border-0 rounded p-0 mb-2"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_vm._l((_vm.order.files.client_uploads),function(file,index){return _c('tr',{key:index + 'ofiles'},[_c('td',[_c('a',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","word-break":"break-all"},on:{"click":function($event){return _vm.downloadFile(file)}}},[_vm._v(_vm._s(file.file_name || ""))])]),_c('td',[_vm._v(_vm._s(file.date || ""))]),_c('td',[_vm._v(_vm._s(file.type || ""))]),_c('td',[(_vm.is_downloading && _vm.activeFile.id == file.id)?_c('span',[_c('img',{attrs:{"width":"30px","src":_vm.loading_gif,"alt":"Loading..."}})]):_c('button',{staticClass:"btn btn-outline-success btn-sm",on:{"click":function($event){return _vm.downloadFile(file)}}},[_c('span',{staticClass:"icon-download mr-0 mr-sm-1"}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Download")])])])])})],2)])]):_c('p',{staticClass:"p-2 bg-light border-0 rounded"},[_vm._v(" No order files. "),_c('br'),_vm._v(" Files that you have uploaded will appear here. ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Filename")]),_c('th',[_vm._v("Uploaded At")]),_c('th',[_vm._v("Uploaded By")]),_c('th',[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Filename")]),_c('th',[_vm._v("Uploaded At")]),_c('th',[_vm._v("Uploaded By")]),_c('th',[_vm._v("Action")])])])
}]

export { render, staticRenderFns }